.button {
  border-style: solid;
  border-width: 1px;

  color: var(--palette-text-000);
}

  .button:not(.disabled),
  .button:not(:disabled) {
    background-color: #333;
    border-color: #333;
  }

  .button:hover, .button.mouseHover {
    background-color: #444;
    border-color: #444;
  }

  .button:active, .button.active {
    background-color: #444;
    border-color: #444;
  }

  .button.disabled {
    color: var(--palette-grey-400);
    background-color: var(--palette-grey-200);
    border-color: var(--palette-grey-200);
  }

