.root {
  font-size: var(--font-size-2);
  font-weight: var(--font-weight-primary-semi-bold);
  font-family: var(--font-family-primary);
  line-height: 1.285;
  color: var(--palette-text-500);
}
.likely {
  color: var(--palette-error-500);
}
