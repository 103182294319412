.button {
  width: 100%;
  justify-content: space-between;
  padding: 0;
}

.modal {
  max-width: 500px;
  padding: var(--spacing-2) var(--spacing-3) var(--spacing-3) var(--spacing-3);
}
